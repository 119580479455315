import * as React from 'react'
import SEO from '../components/seo'
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import Project from '../templates/project'
import Layout from '../components/Layout'
import VariableHeading from '../components/variable-heading'
import styled from 'styled-components'

const NotFoundWrapper = styled.div`
  position: relative;
  padding: ${props => props.theme.space[7]}px 0 ${props => props.theme.space[5]}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NotFoundImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20vw;
  max-width: 100%;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
`

const NotFoundContent = styled.div`
  text-align: center;
  padding: 0 0 ${props => props.theme.space[10]}px;
  
  p {
    font-family: ${props => props.theme.fonts.variable};
    font-size: ${props => props.theme.fontSizes[4]}px;
    margin-bottom: 40px;
  }
  
  a {
    display: inline-block;
    background-color: ${props => props.theme.colors.black};
    padding: 24px 40px;
    text-decoration: none;
    color: #fff;
    font-size: ${props => props.theme.fontSizes[1]}px;
    font-weight: 600;
    text-transform: uppercase;
    transition: background 0.12s ease-in-out;
    
    &:hover {
      background: ${props => props.theme.colors.blue}
    }
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page not found"/>

    <NotFoundWrapper>
      <VariableHeading/>
      <NotFoundImage src='/britney.gif' alt=''/>
    </NotFoundWrapper>

    <NotFoundContent>
      <p>We couldn't find the page you were looking for. I swear this usually never happens to us.</p>

      <a href='/'>Return home</a>
    </NotFoundContent>
  </Layout>
)

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    project: Project,
  },
})
