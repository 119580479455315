/* eslint-disable no-useless-escape */

import React, { useRef, useEffect } from 'react'
import { window, document } from 'browser-monads'
import styled from 'styled-components'
import { isMobile } from "react-device-detect"

const Heading = styled.h1`
  color: #fff;
  font-family: 'Aeonik Pro Variable';
  font-size: 10vw;
  text-align: center;
  z-index: 100;
  mix-blend-mode: difference;
`

const HeadingMobile = styled.h1`
  font-family: 'Aeonik Pro';
  font-size: ${props => props.theme.fontSizes[7]}px;
  text-align: center;
`

const VariableHeading = ({ heading }) => {
  const stringRef = useRef()

  let mouse = {
    x: 0,
    y: 0,
  }

  let cursor = {
    x: window.innerWidth,
    y: window.innerHeight,
  }

  const halfWindow = window.innerWidth / 2

  let chars

  useEffect(() => {
    addEventListeners()

    return () => removeEventListeners()
  })

  const addEventListeners = () => {
    import ('splitting').then(module => {
      const Splitting = module.default

      Splitting({
        by: 'chars',
      })

      chars = document.querySelectorAll('[data-wave] .char')

      document.addEventListener('mousemove', onMouseMove)

      animate()
    }).catch(error => {
      console.log('An error occurred importing Splitting.JS')
    })
  }

  const removeEventListeners = () => {
    document.removeEventListener('mousemove', onMouseMove)
  }

  const onMouseMove = (e) => {
    cursor.x = e.clientX

  }

  const animate = () => {
    mouse.x += (cursor.x - mouse.x) / 5

    requestAnimationFrame(() => {
      animate()
    })

    chars.forEach(char => {
      const diff = Math.abs(mouse.x - char.getBoundingClientRect().left)
      const perc = diff / halfWindow
      const weight = 900 - (perc * 700)

      char.style = `font-variation-settings: "wght" ${weight}`
    })
  }

  if (isMobile) return <HeadingMobile>{heading ? heading : 'Well, this is weird.'}</HeadingMobile>

  return (
    <Heading data-splitting data-wave ref={stringRef}>{heading ? heading : 'Well, this is weird.'}</Heading>
  )
}

export default VariableHeading
